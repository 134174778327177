
















































































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
// utilities
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import dateHelper from "@/Scripts/utilities/date-helper";
import moment from "moment";
//  types
import { PerformanceIndicatorPeriod } from "@/types/PerformanceIndicatorPeriod";
import { IPlotLine } from "@/types/highcharts/plotLine";
import { LogData } from "@/types/logData";
import { IPlotBand } from "@/types/highcharts/plotBand";
import { ISO19030Event } from "@/types/ISO19030Event";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class PerformanceIndicatorChart extends Vue {
  @Prop() title!: string;
  @Prop() isAnyEventUpdated = false;
  @Prop({ required: true }) periods!: PerformanceIndicatorPeriod[];
  @Prop({ required: true }) performanceData!: LogData;
  @PropSync("eventsList", { required: true }) syncEvents!: ISO19030Event[];

  chart!: any;
  chartLoaded = false;
  selectedEvent: ISO19030Event = {} as ISO19030Event;
  mutatedPeriods: PerformanceIndicatorPeriod[] = JSON.parse(JSON.stringify(this.periods));

  @Watch("eventsList")
  onEventsListChanged(): void {
    setTimeout(() => {
      this.chart.update(this.ChartOptions, true);
      this.chart.reflow();
    }, 500);
  }

  @Watch("selectedEvent")
  onSelectedDockingEventChanged(): void {
    //  re-setup evaluation period and recalculate statistics when event changed
    this.setDryDockingEvaluationPeriod();
  }

  get htmlStatistics(): string | undefined {
    switch (this.title) {
      case "DryDocking Performance":
        return `<ul>
                  <li>Evaluation period average: <strong>${this.setEvaluationPeriodAverage}</strong></li>
                  <li>Reference periods average: <strong>${this.setReferencesPeriodsAverage}</strong></li>
                  <li>Difference in performance: <strong>${this.setDryDockingDifferenceInPerformance}</strong></li>
                </ul>`;
      case "InService Performance":
      case "Maintenance Effect":
        return `<ul>
                  <li>Evaluation period average: <strong>${this.setEvaluationPeriodAverage}</strong></li>
                  <li>Reference period average: <strong>${this.setSelectedReferencePeriodAverage}</strong></li>
                  <li>Difference in performance: <strong>${this.setDifferenceInPerformanceOfSelectedEvent}</strong></li>
                </ul>`;
      case "Maintenance Trigger":
        return `<ul>
                  <li>Reference period average: <strong>${this.setSelectedReferencePeriodAverage}</strong></li>
                  <li>Maintenance trigger level (%): <strong>${this.setTriggerValue}</strong></li>
                  ${
                    this.selectedEvaluationPeriod
                      ? `<li>Trigger period start date: <strong>${this.formatDateString(this.selectedEvaluationPeriod?.periodStartDate)}</strong></li>
                    <li>Trigger period end date: <strong>${this.formatDateString(this.selectedReferencePeriod?.periodEndDate)}</strong></li>
                    <li>Difference in performance: <strong>${this.setDifferenceInPerformanceOfSelectedEvent}</strong></li>`
                      : `<li
                      style="
                        display: ${this.hasCalculatedPeriods ? "block;" : "none;"}
                        color: #4caf50;
                      "
                    >
                      Maintenance trigger level not reached
                    </li>`
                  }
                </ul>`;
    }
  }

  get Highchart(): any {
    return this.chart;
  }

  get vesselId(): number {
    return Number(this.$route.params.vesselId);
  }

  get referencePeriods(): PerformanceIndicatorPeriod[] {
    return this.mutatedPeriods.filter(period => period.periodType === "Reference Period");
  }

  get referencePeriodsAverage(): number {
    return this.referencePeriods.reduce((acc: number, period: PerformanceIndicatorPeriod) => acc + period.average, 0) / this.referencePeriods.length;
  }

  get selectedReferencePeriod(): PerformanceIndicatorPeriod {
    let selectedReferencePeriod!: PerformanceIndicatorPeriod;
    if (this.selectedEvent) {
      //  find period of selected event and set it as current
      const findPeriodOfSelectedEvent = this.periods.find(period => period.eventId === this.selectedEvent.id);
      if (findPeriodOfSelectedEvent) selectedReferencePeriod = findPeriodOfSelectedEvent;
    }
    return selectedReferencePeriod;
  }

  get isEvaluationPeriodAvailable(): boolean {
    return Boolean(this.selectedEvaluationPeriod);
  }

  get selectedEvaluationPeriod(): PerformanceIndicatorPeriod {
    let selectedEvaluationPeriod!: PerformanceIndicatorPeriod;
    if (this.selectedEvent) {
      const findPeriodOfSelectedEvent = this.evaluationPeriods.find(period => period.eventId === this.selectedEvent.id);
      if (findPeriodOfSelectedEvent) selectedEvaluationPeriod = findPeriodOfSelectedEvent;
    }

    return selectedEvaluationPeriod;
  }

  get lastDryDockingEvent(): ISO19030Event {
    const currentDate = moment().format("YYYY-MM-DD");
    let lastDryDockingEvent!: ISO19030Event;
    this.dryDockingEvents.forEach(event => {
      const diff = moment(event.endTimestamp).diff(moment(currentDate), "days");
      if (diff > 0) {
        if (lastDryDockingEvent) {
          if (moment(event.endTimestamp).diff(moment(lastDryDockingEvent.endTimestamp), "days") < 0) {
            lastDryDockingEvent = event;
          }
        }
      } else {
        lastDryDockingEvent = event;
      }
    });
    return lastDryDockingEvent;
  }

  get lastMaintenanceEvent(): ISO19030Event {
    const currentDate = moment().format("YYYY-MM-DD");
    let lastMaintenanceEvent!: ISO19030Event;
    this.maintenanceEvents.forEach(event => {
      const diff = moment(event.endTimestamp).diff(moment(currentDate), "days");
      if (diff > 0) {
        if (lastMaintenanceEvent) {
          if (moment(event.endTimestamp).diff(moment(lastMaintenanceEvent.endTimestamp), "days") < 0) {
            lastMaintenanceEvent = event;
          }
        }
      } else {
        lastMaintenanceEvent = event;
      }
    });
    return lastMaintenanceEvent;
  }

  get dryDockingEvents(): ISO19030Event[] {
    return this.syncEvents.filter(event => event.eventType === "Docking");
  }

  get maintenanceEvents(): ISO19030Event[] {
    return this.syncEvents.filter(event => event.eventType === "Maintenance");
  }

  get chartEvents(): ISO19030Event[] {
    if (this.isMaintenanceEffectChart) {
      return this.syncEvents.filter(event => event.eventType === "Maintenance");
    } else {
      return this.dryDockingEvents;
    }
  }

  get evaluationPeriods(): PerformanceIndicatorPeriod[] {
    return this.mutatedPeriods.filter(period => period.periodType === "Evaluation Period");
  }

  get hasCalculatedPeriods(): boolean {
    return Boolean(this.periods.length);
  }

  get differenceInPerformanceOfSelectedEvent(): number {
    return this.selectedEvaluationPeriod?.average - this.selectedReferencePeriod?.average;
  }

  get setDifferenceInPerformanceOfSelectedEvent(): string {
    return Boolean(this.differenceInPerformanceOfSelectedEvent) ? `${this.differenceInPerformanceOfSelectedEvent?.toFixed(1)}%` : "N/A";
  }

  get isDryDockingChart(): boolean {
    return this.title.toLowerCase().includes("docking");
  }

  get isMaintenanceEffectChart(): boolean {
    return this.title === "Maintenance Effect";
  }

  get setEvaluationPeriodAverage(): string {
    return this.isEvaluationPeriodAvailable ? `${this.selectedEvaluationPeriod.average.toFixed(1)}%` : "N/A";
  }

  get setReferencesPeriodsAverage(): string {
    if (Boolean(this.referencePeriodsWithoutCurrentAverage)) {
      return `${this.referencePeriodsWithoutCurrentAverage.toFixed(1)}%`;
    } else {
      return "N/A (There is no reference periods to calculate the average)";
    }
  }

  get setSelectedReferencePeriodAverage(): string {
    return this.selectedReferencePeriod ? `${this.selectedReferencePeriod.average.toFixed(1)}%` : "N/A";
  }

  get setTriggerValue(): number | string | null | undefined {
    return this.selectedEvent ? this.selectedEvent?.triggerValue : "N/A";
  }

  get referencePeriodsWithoutCurrentAverage(): number {
    const referencePeriods = this.periods.filter(period => period.periodType === "Reference Period");
    const selectedReferencePeriod = this.periods.find(period => period.id === this.selectedReferencePeriod?.id);
    if (selectedReferencePeriod) {
      const referencePeriodsWithoutCurrent = referencePeriods.filter(period => {
        // check if period end date is before selected reference period start date
        if (moment(period.periodEndDate).isBefore(selectedReferencePeriod?.periodStartDate, "day") && period.id !== this.selectedReferencePeriod?.id) {
          return period;
        }
      });
      return referencePeriodsWithoutCurrent.reduce((acc: number, period: PerformanceIndicatorPeriod) => acc + period.average, 0) / referencePeriodsWithoutCurrent.length;
    } else {
      return referencePeriods.reduce((acc: number, period: PerformanceIndicatorPeriod) => acc + period.average, 0) / referencePeriods.length;
    }
  }

  get dryDockingDifferenceInPerformance(): number {
    if (!this.selectedReferencePeriod) return 0;
    const referencePeriodsWithoutCurrent = this.referencePeriods.filter(period => period.id !== this.selectedReferencePeriod?.id);
    const referencePeriodsWithoutCurrentAverage =
      referencePeriodsWithoutCurrent.reduce((acc: number, period: PerformanceIndicatorPeriod) => acc + period.average, 0) / referencePeriodsWithoutCurrent.length;
    return this.selectedReferencePeriod?.average - referencePeriodsWithoutCurrentAverage;
  }

  get setDryDockingDifferenceInPerformance(): string {
    return Boolean(this.dryDockingDifferenceInPerformance) ? `${this.dryDockingDifferenceInPerformance.toFixed(1)}%` : "N/A";
  }

  setDryDockingEvaluationPeriod(): void {
    //  set selected reference period as evaluation period for dry docking chart
    if (!this.isDryDockingChart || !this.selectedReferencePeriod) return;
    this.mutatedPeriods = this.periods.filter((period: PerformanceIndicatorPeriod) => {
      if (moment(period.periodEndDate).isBefore(this.selectedEvent.startTimestamp, "day") || period.id === this.selectedReferencePeriod.id) {
        if (this.selectedReferencePeriod.id === period.id) {
          Vue.set(period, "periodType", "Evaluation Period");
        } else {
          Vue.set(period, "periodType", "Reference Period");
        }
        return period;
      }
    });
  }

  isPeriodReferenceType(type: string): boolean {
    return type === "Reference Period";
  }

  get periodLines(): any {
    if (!this.mutatedPeriods.length) return [];
    const periodLines: any = [];
    this.mutatedPeriods.forEach((period: any) => {
      //  show period line if they have existed event
      if (this.syncEvents.filter(event => event.id === period.eventId).length) {
        periodLines.push(
          this.PeriodLine(
            Date.parse(period.periodStartDate),
            Date.parse(period.periodEndDate),
            period.average,
            this.isPeriodReferenceType(period.periodType) ? "R" : "E",
            this.isPeriodReferenceType(period.periodType) ? "#ff5252" : "#0037ff"
          )
        );
      }
    });

    //  group lines by id to display common legend
    const firstReferenceIndex = periodLines.findIndex((line: any) => line.name === "Reference period");
    const firstEvaluationIndex = periodLines.findIndex((line: any) => line.name === "Evaluation period");
    if (firstReferenceIndex >= 0) {
      Vue.set(periodLines[firstReferenceIndex], "id", "Reference");
      Vue.delete(periodLines[firstReferenceIndex], "linkedTo");
    }
    if (firstEvaluationIndex >= 0) {
      Vue.set(periodLines[firstEvaluationIndex], "id", "Evaluation");
      Vue.delete(periodLines[firstEvaluationIndex], "linkedTo");
    }
    return periodLines;
  }

  PeriodLine(startDateMS: number, endDateMS: number, average: number, text: string, color: string): any {
    return {
      name: `${text === "R" ? "Reference" : "Evaluation"} period`,
      type: "line",
      color: color,
      lineWidth: 2,
      data: [[startDateMS, average], this.periodLineLabel(endDateMS, average, text, color)],
      linkedTo: `${text === "R" ? "Reference" : "Evaluation"}`,
      marker: {
        radius: 2.5,
        symbol: "circle",
      },
      zIndex: 5,
    };
  }

  periodLineLabel(dateMS: number, average: number, text: string, color: string): any {
    return {
      x: dateMS,
      y: average,
      dataLabels: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          return `<span class="period-label" style="color: ${color}">${text}</span>`;
        },
        zIndex: 10,
      },
    };
  }

  get seriesData(): any {
    if (!this.performanceData.data) return [];
    if (this.chart.series.length) {
      /* IMPORTANT:
              There is also a bug in Highcharts when it has more than 2 series
              and they are updated dynamically it "loses" correct indexes of the
              series and displays data in a weird manner so the solution is to
              clear the series array before it will be updated */
      while (this.chart.series.length) {
        this.chart.series[0].remove();
      }
    }
    const scatterPoints = Object.entries(this.performanceData.data).map(([timestamp, value]) => {
      return [Date.parse(`${timestamp}Z`), value !== null ? Number(value) : null];
    });
    const scatterPointsSerie = {
      name: "Speed Loss",
      type: "scatter",
      data: scatterPoints,
      stickyTracking: false,
      /* IMPORTANT:
              https://www.highcharts.com/forum/viewtopic.php?f=9&t=44589
              Turns out scatter points have a bug. When they get updated they can be displayed as line-through-dots
              with property lineWidth: 2 so here we need to set the lineWidth: 0, otherwise it should be set always
              through chart.series.forEach loop in serie.options.lineWidth
              */
      lineWidth: 0,
      marker: {
        radius: 2.5,
        symbol: "circle",
        fillColor: "#448eeb5c",
      },
    };
    const series = [...this.periodLines, scatterPointsSerie];

    return series;
  }

  get plotLines(): IPlotLine[] {
    if (this.isMaintenanceEffectChart) {
      return [...this.maintenancePlotLines];
    } else {
      return [...this.dockingPlotLines];
    }
  }

  get plotBands(): IPlotBand[] {
    return [...this.dryDockingPlotBands, this.selectedEventPlotBand];
  }

  get maintenancePlotLines(): IPlotLine[] {
    if (!this.syncEvents.length) return [];
    const plotLines: IPlotLine[] = [];
    this.syncEvents.forEach((event: any) => {
      if (event.eventType !== "Maintenance") return;
      const html = this.getPlotLineLabelsHtml(event);
      plotLines.push({
        id: `plotline-${event.id}`,
        color: "#2196F3",
        value: moment.utc(event.startTimestamp).valueOf(),
        width: 2,
        zIndex: 5,
        label: {
          rotation: 0,
          style: { color: "#2196F3" },
          text: html,
          useHTML: true,
          y: -15,
          x: -11,
        },
      });
    });
    return plotLines;
  }

  get dockingPlotLines(): IPlotLine[] {
    if (!this.syncEvents.length) return [];
    const plotLines: IPlotLine[] = [];
    this.syncEvents.forEach(event => {
      if (event.eventType !== "Docking") return;
      plotLines.push(
        {
          id: `plotline-${event.id}`,
          color: "#008000",
          value: moment.utc(event.startTimestamp).valueOf(),
          width: 2,
          zIndex: 7,
          label: {
            rotation: 0,
            style: { color: "#008000" },
            text: this.getPlotLineLabelsHtml(event, "start", "d"),
            useHTML: true,
            y: -15,
            x: -11,
          },
        },
        {
          id: `plotline-${event.id}`,
          color: "#008000",
          value: moment.utc(event.endTimestamp).valueOf(),
          width: 2,
          zIndex: 7,
          label: {
            rotation: 0,
            style: { color: "#008000" },
            text: this.getPlotLineLabelsHtml(event, "end", "d"),
            useHTML: true,
            y: -15,
            x: -11,
          },
        }
      );
    });
    return plotLines;
  }

  getPlotLineLabelsHtml(event: ISO19030Event, timestamp = "start", letter = "e"): string {
    return `
              <span class="plot-line-label-icon mdi mdi-alpha-${letter}-circle" style="position: relative; z-index: 10;"></span>
              <div class="plot-line-tooltip">
                <p
                  style="position: absolute;
                  right: 10px;
                  top: 5px;"
                ><b>ID: ${event.id}</b></p>
                <p style="margin-bottom: 10px;">
                  <b style="text-transform: capitalize;">${timestamp} date: ${
      timestamp === "start" ? dateHelper.getFormatedDateString(event.startTimestamp) : dateHelper.getFormatedDateString(event.endTimestamp)
    }</b>
              </p>
                <p style="display: ${!this.isMaintenanceEffectChart ? "block" : "none"}">${timestamp === "start" ? "In-docking" : "Out-docking"}</p>
                <p style="display: ${this.isMaintenanceEffectChart ? "block" : "none"}">${event.comment ?? "No comment"}</p>
              </div>
              `;
  }

  get dryDockingPlotBands(): IPlotBand[] {
    const plotBands: IPlotBand[] = [];
    if (!this.syncEvents.length || this.isMaintenanceEffectChart) return plotBands;

    this.syncEvents.forEach(event => {
      if (event.eventType !== "Docking") return;
      plotBands.push({
        from: moment.utc(event.startTimestamp).valueOf(),
        to: moment.utc(event.endTimestamp).valueOf(),
        id: event.id,
        className: "performance-graph__plot-bands",
        color: "#0080001f",
        zIndex: 4,
      });
    });

    return plotBands;
  }

  get selectedEventPlotBand(): IPlotBand {
    if (this.title === "Maintenance Trigger") return {} as IPlotBand;
    const eventPeriods: PerformanceIndicatorPeriod[] = this.mutatedPeriods.filter(period => period.eventId === this.selectedEvent.id);
    let plotBandStartDate!: string | undefined | null;
    let plotBandEndDate!: string | undefined | null;

    if (this.isMaintenanceEffectChart) {
      plotBandStartDate = eventPeriods.find(period => {
        //  plotBandStartDate is the start date of the period BEFORE the event
        if (moment(period.periodStartDate).isBefore(this.selectedEvent.startTimestamp, "day")) {
          return period;
        }
      })?.periodStartDate;
      plotBandEndDate = eventPeriods.find(period => {
        //  plotBandEndDate is the end date of the period AFTER the event
        if (moment(period.periodEndDate).isAfter(this.selectedEvent.startTimestamp, "day")) {
          return period;
        }
      })?.periodEndDate;
    } else {
      plotBandStartDate = this.selectedEvent?.startTimestamp;
      plotBandEndDate = eventPeriods.find(period => {
        if (moment(period.periodEndDate).isAfter(this.selectedEvent.startTimestamp, "day") && period.periodType === "Evaluation Period") {
          return period;
        }
      })?.periodEndDate;
    }

    if (!plotBandStartDate && !plotBandEndDate) return {} as IPlotBand;

    return {
      from: moment.utc(plotBandStartDate).valueOf(),
      to: moment.utc(plotBandEndDate).valueOf(),
      id: this.selectedEvent.id,
      className: "performance-graph__plot-bands",
      color: "#6363f636",
      zIndex: 4,
    };
  }

  formatDateForDisplay(start: string, end: string): string {
    if (!dateHelper.getFormatedDateString(end).length) return dateHelper.getFormatedDateString(start);
    else return `${dateHelper.getFormatedDateString(start)} - ${dateHelper.getFormatedDateString(end)}`;
  }

  formatDateString(date: string | null): string {
    if (!date) return "";
    return dateHelper.getFormatedDateString(date);
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    return {
      type: "line",
      height: 300,
      spacingTop: 30,
      zoomType: "x",
      style: { fontFamily: "Helvetica Neue" },
    };
  }
  get ChartOptions(): any {
    if (!this.chartLoaded || !Highcharts) return {};
    const ctx = this;
    const options = {
      chart: ctx.chartSettings,
      title: { text: "" },
      legend: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: "Percent (%)",
          style: { color: "#331714" },
        },
        labels: {
          format: "{value}",
          style: { color: "#331714" },
        },
        min: -50,
        max: 50,
      },
      xAxis: {
        type: "datetime",
        labels: {
          style: { color: "#331714" },
        },
        plotLines: this.plotLines,
        plotBands: this.plotBands,
      },
      plotOptions: {
        scatter: {
          lineWidth: 0,
          marker: {
            radius: 2.5,
            symbol: "circle",
            fillColor: "#448eeb5c",
          },
          tooltip: {
            outside: true,
            headerFormat: "<small>{point.key}</small><br>",
            pointFormat: "Value: <strong>{point.y}</strong>",
            valueDecimals: 1,
            valueSuffix: "%",
            xDateFormat: "%d. %b, %Y",
            zIndex: 7,
          },
        },
        line: {
          tooltip: {
            outside: true,
            headerFormat: "<small>{point.key}</small><br>",
            pointFormat: "Value: <strong>{point.y}</strong>",
            valueDecimals: 1,
            valueSuffix: "%",
            xDateFormat: "%d. %b, %Y",
            zIndex: 7,
          },
        },
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      tooltip: {
        format: "{value}",
        zIndex: 50,
      },
      series: this.seriesData,
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }

  mounted(): void {
    if (this.title !== "Maintenance Effect") {
      this.selectedEvent = this.lastDryDockingEvent;
    } else {
      this.selectedEvent = this.lastMaintenanceEvent;
    }
    this.setDryDockingEvaluationPeriod();
  }
}
