var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{style:(("max-width: " + _vm.maxWidth + "px;")),attrs:{"value":0}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"white--text",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Events")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3 font-weight-semibold",attrs:{"small":"","color":"white","max-width":"150"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openEventModal()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New event ")],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"dense":"","loading-text":"Loading... Please wait","no-data-text":"No events","items-per-page":10,"sort-by":"date","sort-desc":true},scopedSlots:_vm._u([{key:"header.dates",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.eventType",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatEventDate(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openEventModal('edit', item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"primary lighten-3"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit event")])]),_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEvent(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"primary lighten-3"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete event")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)},"click:outside":_vm.cancel},model:{value:(_vm.eventModal),callback:function ($$v) {_vm.eventModal=$$v},expression:"eventModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Create event")])]),_c('v-form',{staticClass:"mt-2"},[_c('v-card-text',[_c('v-select',{attrs:{"rules":[_vm.typeSelectValidation.required],"value":"trendEventTypeName","label":"Event type","prepend-icon":"mdi-format-list-bulleted-type","items":_vm.eventTypes,"item-text":"trendEventTypeName","item-value":"trendEventTypeId"},model:{value:(_vm.event.eventType),callback:function ($$v) {_vm.$set(_vm.event, "eventType", $$v)},expression:"event.eventType"}}),(_vm.isEventDryDocking)?_c('div',[_c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.dateValidation.required],"label":"In-docking date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.event.startTimestamp),callback:function ($$v) {_vm.$set(_vm.event, "startTimestamp", $$v)},expression:"event.startTimestamp"}},'v-text-field',attrs,false),on))]}}],null,false,2735545862),model:{value:(_vm.indockingDateMenu),callback:function ($$v) {_vm.indockingDateMenu=$$v},expression:"indockingDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.indockingDateMenu = false}},model:{value:(_vm.event.startTimestamp),callback:function ($$v) {_vm.$set(_vm.event, "startTimestamp", $$v)},expression:"event.startTimestamp"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.dateValidation.required],"label":"Out-docking date","prepend-icon":"mdi-calendar","disabled":_vm.event.startTimestamp === null,"readonly":""},model:{value:(_vm.event.endTimestamp),callback:function ($$v) {_vm.$set(_vm.event, "endTimestamp", $$v)},expression:"event.endTimestamp"}},'v-text-field',attrs,false),on))]}}],null,false,3474714540),model:{value:(_vm.outdockingDateMenu),callback:function ($$v) {_vm.outdockingDateMenu=$$v},expression:"outdockingDateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.event.startTimestamp},on:{"input":function($event){_vm.outdockingDateMenu = false}},model:{value:(_vm.event.endTimestamp),callback:function ($$v) {_vm.$set(_vm.event, "endTimestamp", $$v)},expression:"event.endTimestamp"}})],1),_c('v-tooltip',{attrs:{"top":"","max-width":"400px","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mb-1 ml-1 flex-grow-0",staticStyle:{"cursor":"pointer"},attrs:{"color":"primary lighten-2","left":""}},on),[_vm._v("mdi-alert-circle")])]}}],null,false,411864530)},[_c('span',{staticClass:"mx-auto"},[_vm._v("Started collecting speed-loss performance-values "+_vm._s(_vm.formattedDataMinDate)+". Will not be able to calculate vessel performance according to ISO 19030 before this date.")])])],1),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Maintenance Threshold %","prepend-icon":"mdi-percent-box-outline","rules":[_vm.thresholdValidation.required],"type":"text"},model:{value:(_vm.event.maintenanceThreshold),callback:function ($$v) {_vm.$set(_vm.event, "maintenanceThreshold", _vm._n($$v))},expression:"event.maintenanceThreshold"}})],1):_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.dateValidation.required],"label":"Event date","prepend-icon":"mdi-calendar","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","max-width":"400px","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mb-1 ml-1 flex-grow-0",staticStyle:{"cursor":"pointer"},attrs:{"color":"primary lighten-2","left":""}},on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('span',{staticClass:"mx-auto"},[_vm._v("Started collecting speed-loss performance-values "+_vm._s(_vm.formattedDataMinDate)+". Will not be able to calculate vessel performance according to ISO 19030 before this date.")])])]},proxy:true}],null,true),model:{value:(_vm.event.startTimestamp),callback:function ($$v) {_vm.$set(_vm.event, "startTimestamp", $$v)},expression:"event.startTimestamp"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.event.startTimestamp),callback:function ($$v) {_vm.$set(_vm.event, "startTimestamp", $$v)},expression:"event.startTimestamp"}})],1),_c('v-textarea',{attrs:{"label":"Comment","prepend-icon":"mdi-comment-outline","rules":[function (v) { return v && v.length <= 100 || 'Max 100 characters'; }],"auto-grow":"","rows":"2","row-height":"20","counter":"","clearable":""},model:{value:(_vm.event.comment),callback:function ($$v) {_vm.$set(_vm.event, "comment", $$v)},expression:"event.comment"}})],1)],1),_c('v-card-actions',{staticClass:"pt-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","form":"edit-event-form","disabled":_vm.isSaveButtonDisabled},on:{"click":_vm.saveEvent}},[_vm._v("Save")]),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"grey","text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cancel")])],1)],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDelete",staticClass:"confirm-dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }